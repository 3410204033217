import '../../../src/styles/styles.css'; // The path should be relative to the current file

// React related imports
import React, { useContext, useEffect, useState } from "react";

// Third-party libraries imports
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactTooltip from 'react-tooltip';

// Context imports
import { AuthContext } from "../../context/AuthContext";

// Utility imports  
import { daysBetween, todayStart, formatDateToUS } from "../../utils/theDateHelper.js";

// Component imports
import HabitItem from './HabitItem'; // Adjust the path as needed

// Styles and API imports
import { api } from "../../api/api.js"; // Adjust the path as needed

import SummaryHabitChart from './SummaryHabitChart'; // Adjust the path as needed

// At the top of your component file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";



const HabitWidgetDaily = ({ handleAuthorized }) => {

  const [daysOfWork, setDaysOfWork] = useState([]);
  const [habitDay, setHabitDay] = useState(0);
  const [habitStats, setHabitStats] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    buildData();
  }, [user._id]);

  const buildData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/api/user/populatedaysofwork/${user._id}`);
      const habitStats = calculateHabitStats(response.data);
      setDaysOfWork(response.data);
      setHabitStats(habitStats);

    } catch (err) {
      console.error(err);
      handleAuthorized(false);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = () => {
    // Assuming 'daysOfWork' is an array of days, each containing an array of habits
    // Example format of daysOfWork: [{ date: '2023-03-01', habits: [{ name: 'Habit1', complete: true }, { name: 'Habit2', complete: false }] }, ...]

    const chartData = daysOfWork.map(day => {
      const dateFormatted = new Date(day.date).toLocaleDateString();
      const dayStats = {
        date: dateFormatted,  // Use date as key
        week: 0,
        month: 0,
        year: 0,
        inception: 0
      };

      // Calculate stats for each habit
      day.habits.forEach(habit => {
        // Increment the stats based on some condition, example given below
        // You can adjust these conditions based on your actual logic
        dayStats.week += habit.complete ? 1 : 0;
        dayStats.month += habit.complete ? 1 : 0;
        dayStats.year += habit.complete ? 1 : 0;
        dayStats.inception += habit.complete ? 1 : 0;
      });

      return dayStats;
    });

    return chartData;
  };

  const calculateHabitStats = (days) => {
    return days.reduce((acc, day) => {
      day.habits.forEach((habit) => {
        if (!acc[habit.habit._id]) {
          acc[habit.habit._id] = { week: 0, month: 0, year: 0, inception: 0 };
        }
        const dateDiff = daysBetween(new Date(todayStart()), new Date(day.date));
        if (habit.complete) {
          // Directly increment stats in the accumulator
          incrementStats(acc[habit.habit._id], dateDiff);
        }
      });
      return acc;
    }, {});
  };

  const incrementStats = (stats, dateDiff) => {
    if (dateDiff <= 6) stats.week++;
    if (dateDiff <= 27) stats.month++;
    if (dateDiff <= 364) stats.year++;
    stats.inception++;
  };

  // Function to update stats based on date difference
  const updateStatsBasedOnDateDiff = (stats, dateDiff, increment) => {
    if (dateDiff >= 6) stats.week += (increment ? 1 : -1);
    if (dateDiff >= 27) stats.month += (increment ? 1 : -1);
    if (dateDiff >= 364) stats.year += (increment ? 1 : -1);
    stats.inception += (increment ? 1 : -1);
  };

  // Increment habit stats
  const incrementHabitStats = (habitId, dateDiff) => {
    // console.log("Incrementing habit stats");
    // console.log("habitId: ", habitId);

    setHabitStats(currentStats => {
      const updatedStats = { ...currentStats };

      // console.log("updatedStats: ", updatedStats);

      if (!updatedStats[habitId]) {
        updatedStats[habitId] = { week: 0, month: 0, year: 0, inception: 0 };
      }
      updateStatsBasedOnDateDiff(updatedStats[habitId], dateDiff, true);
      return updatedStats;
    });
  };

  // Decrement habit stats
  const decrementHabitStats = (habitId, dateDiff) => {
    setHabitStats(currentStats => {
      const updatedStats = { ...currentStats };
      if (!updatedStats[habitId]) {
        updatedStats[habitId] = { week: 0, month: 0, year: 0, inception: 0 };
      }
      updateStatsBasedOnDateDiff(updatedStats[habitId], dateDiff, false);
      return updatedStats;
    });
  };


  const changeHabitDate = async (date) => {
    setHabitDay(date);
  };

  // Function to handle the drag and drop
  const handleDragEnd = async (result) => {
    //console.log("result is " + JSON.stringify(result));

    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      return;
    }

    //Update the local copy
    //const updatedDaysOfWork = [...daysOfWork[habitDay].habits];
    const updatedDaysOfWork = [...daysOfWork];
    const [movedHabitItem] = updatedDaysOfWork[habitDay].habits.splice(source.index, 1);
    updatedDaysOfWork[habitDay].habits.splice(destination.index, 0, movedHabitItem);
    setDaysOfWork(updatedDaysOfWork);

    //Update the database
    try {
      const res = await api.put("/api/user/updatehabitorder",
        {
          dayOfWork: updatedDaysOfWork[habitDay]._id,
          habits: updatedDaysOfWork[habitDay].habits,
          day: updatedDaysOfWork[habitDay].date
        })
        .then(response => {
          // console.log('Response:', response.data); // Log the response data
          // Handle the successful response
        })
        .catch(error => {
          //console.log('Error:', error); // Log the error details
          // Handle errors, including timeouts
        });
    } catch (err) {
      console.log(err);
    }
  };

  //Function called by HabitItem 
  const handleComplete = async (index, dayId, habitId, newCompleteState) => {
    // Update the day's habits using a functional state update
    setDaysOfWork((currentDays) => {
      const newDays = [...currentDays];
      newDays[habitDay].habits[index].complete = newCompleteState;
      return newDays;
    });

    // Determine the date difference for the habit
    const today = new Date();
    const habitStartDate = new Date(daysOfWork[habitDay].habits[index].habit.createdAt);

    const dateDiff = daysBetween(today, habitStartDate);

    // console.log("Date diff: ", dateDiff);
    // console.log("today: ", today);
    // console.log("habitStartDate: ", habitStartDate);

    // Update habitStats
    if (newCompleteState) {
      incrementHabitStats(habitId, dateDiff);
    } else {
      decrementHabitStats(habitId, dateDiff);
    }

    // API call to update the habit's completion status
    try {
      await api.post(`/api/dayofwork/updatehabits`, {
        dayID: dayId,
        habitID: habitId,
        field: 'complete',
        boolValue: newCompleteState
      });
    } catch (error) {
      console.error('Error updating habit completion: ', error);
    }
  };


  // In the parent component
  const handleFocus = async (index, dayId, habitId, newFocusState) => {
    // Update the day's habits using a functional state update
    setDaysOfWork((currentDays) => {
      const newDays = [...currentDays];
      newDays[habitDay].habits[index].focus = newFocusState;
      return newDays;
    });

    try {
      await api.post(`/api/dayofwork/updatehabits`, { dayID: dayId, habitID: habitId, field: 'focus', boolValue: newFocusState });
      // Update local state if needed
    } catch (error) {
      console.error('Error updating habit focus: ', error);
    }
  };

  // In the parent component
  const handleFrog = async (index, dayId, habitId, newFrogState) => {
    // Update the day's habits using a functional state update
    setDaysOfWork(currentDays => {
      const newDays = [...currentDays];
      newDays[habitDay].habits[index].frog = newFrogState;
      return newDays;
    });

    try {
      await api.post(`/api/dayofwork/updatehabits`, { dayID: dayId, habitID: habitId, field: 'frog', boolValue: newFrogState });
      // Update local state if needed
    } catch (error) {
      console.error('Error updating habit frog: ', error);
    }

  };

  const removeHabit = async (habitId) => {
    // console.log("in removeHabit");
    // console.log("habitId: " + habitId);
    try {
      // API call to remove the habit
      api.post(`/api/user/removehabit`, {
        user: user._id,
        habit: habitId
      });
      // console.log("A");

      // Update the state to remove the habit from the daysOfWork list
      setDaysOfWork(currentDays => {
        // console.log("Current days before update:", currentDays);

        // Iterate through each day
        const updatedDays = currentDays.map(day => {
          // Filter out the removed habit from each day's habits array
          const updatedHabits = day.habits.filter(habit => {
            // console.log("Checking habit:", habit);
            return habit.habit._id !== habitId;
          });

          // Return the updated day with the filtered habits
          return { ...day, habits: updatedHabits };
        });

        // console.log("Days after update:", updatedDays);
        return updatedDays;
      });

    } catch (error) {
      console.error(error);
    }
  };

  const updateHabitDetail = async (user2HabitID, detail) => {
    try {
      await api.put(`/api/user2habit/${user2HabitID}`, { detail });
      // Update local state if needed
    } catch (error) {
      console.error('Error updating habit detail: ', error);
    }
  };

  const updateHabitEnd = async (user2HabitID, end) => {
    try {
      await api.put(`/api/user2habit/${user2HabitID}`, { end });
      // Update local state if needed
    } catch (error) {
      console.error('Error updating habit end time: ', error);
    }
  };

  const updateHabitStart = async (user2HabitID, start) => {
    try {
      await api.put(`/api/user2habit/${user2HabitID}`, { start });
      // Here you can update the local state if needed
      // e.g., setDaysOfWork(updatedDaysOfWork);
    } catch (error) {
      console.error('Error updating habit start time: ', error);
      // Handle errors, such as unauthorized access
    }
  };

  const updateHabitStats = (habitId, newCompleteState) => {
    const updatedHabitStats = { ...habitStats };

    if (newCompleteState) {
      updatedHabitStats[habitId].week += 1;
      updatedHabitStats[habitId].month += 1;
      updatedHabitStats[habitId].year += 1;
      updatedHabitStats[habitId].inception += 1;
    } else {
      updatedHabitStats[habitId].week -= 1;
      updatedHabitStats[habitId].month -= 1;
      updatedHabitStats[habitId].year -= 1;
      updatedHabitStats[habitId].inception -= 1;
    }

    setHabitStats(updatedHabitStats);
  };

  return (
    <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-1">
      <div className="card shadow h-100">
        <div className="card-body p-0 m-0">
          <div className="card-header fw-bold text-secondary d-flex justify-content-between">
            Daily Habits
          </div>
          <SummaryHabitChart rawData={prepareChartData()} />

          <div className="date-selection-section mx-1 px-1 d-flex justify-content-between align-items-center">
            <button
              className="btn btn-sm"
              style={{ backgroundColor: '#277da1', color: 'white' }}
              onClick={() => changeHabitDate(habitDay + 1)}
              disabled={habitDay >= daysOfWork.length - 1}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            <div className="date-display" style={{ flex: 1, textAlign: 'center' }}>
              {daysOfWork.length > 0 ? formatDateToUS(daysOfWork[habitDay].date) : <></>}
            </div>
            <button
              className="btn btn-sm"
              style={{ backgroundColor: '#277da1', color: 'white' }}
              onClick={() => changeHabitDate(habitDay - 1)}
              disabled={habitDay <= 0}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>

          </div>

          <div className="list-group p-0 m-1">

            {
              loading ?
                <>Loading</>
                :
                !daysOfWork ?
                  <>No daysOfWork</>
                  :
                  !daysOfWork[habitDay] ?
                    <></>
                    :
                    (!daysOfWork[habitDay].habits || daysOfWork[habitDay].habits == 0) ?
                      <>
                        <div className="text-center p-3">
                          <p>No habits yet! Ready to set some?</p>
                          <a href="/habits" className="btn btn-add-goal">Go to Habits Page</a>
                        </div>
                      </> :
                      <>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="item-list">
                            {provided => (
                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                {daysOfWork[habitDay].habits.map((habitData, index) => {


                                  return (
                                    <HabitItem
                                      key={habitData._id}
                                      habitStartDate={daysOfWork[habitDay].habits[index].habit.createdAt}
                                      order={index}
                                      dayOfWork={daysOfWork[habitDay]._id}
                                      habitData={habitData}
                                      habitStats={habitStats[habitData.habit._id]}
                                      //
                                      removeHabit={removeHabit}
                                      toggleComplete={handleComplete}
                                      toggleFocus={handleFocus}
                                      toggleFrog={handleFrog}
                                      updateHabitStats={updateHabitStats}
                                      submitStartTime={updateHabitStart}
                                      submitEndTime={updateHabitEnd}
                                      submitDetails={updateHabitDetail}
                                    />
                                  );
                                })}
                                {provided.placeholder}
                              </div>

                            )}
                          </Droppable>
                        </DragDropContext>
                      </>
            }
          </div>
        </div>
        <div className="card-footer p-2">
          {
            (!daysOfWork || daysOfWork.length == 0) || (!daysOfWork[habitDay].habits || daysOfWork[habitDay].habits.lenth == 0) ?
              <>
                0 Daily Habits
              </>
              :
              <>{daysOfWork[habitDay].habits.length} Daily Habits</>
          }
        </div>
      </div >
    </div >
  );
};

export default HabitWidgetDaily;