

const Footer = () => {
  return (
    <div className="container-fluid mt-2">
      <div className="">Copyright © 2022 MoveTowardHappy.</div>
    </div>
  );
};
export default Footer;
