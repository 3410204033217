import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCheckCircle, faBullseye, faFrog, faTrash } from "@fortawesome/free-solid-svg-icons";
// import { api } from "../../api/api.js"; // Adjust the path as needed


const ToDoItem = ({ item, index, deleteToDo, toggleComplete, toggleFocus, toggleFrog }) => {
    // const [isComplete, setIsComplete] = useState(item.complete);
    // const [isFocus, setIsFocus] = useState(item.focus);
    // const [isFrog, setIsFrog] = useState(item.frog);

    // const updateTodoStatus = async (field, value) => {
    //     try {
    //         await api.put(`/api/todo/${item._id}`, { [field]: value });
    //         //if field is true update the daysofWork
    //         try {
    //             await api.post(`/api/dayofwork/updatetodos`, {
    //                 user: userID,
    //                 id: item._id
    //             });
    //         } catch (error) {
    //             console.error('Error updating habit completion: ', error);
    //         }
    //     } catch (error) {
    //         console.error('Error updating todo:', error);
    //     }
    // };

    // const handleComplete = async () => {
    //     setIsComplete(!isComplete);
    //     updateTodoStatus('complete', !isComplete);
    // };

    // const handleFocus = async () => {
    //     setIsFocus(!isFocus);
    //     updateTodoStatus('focus', !isFocus);
    // };

    // const handleFrog = async () => {
    //     setIsFrog(!isFrog);
    //     updateTodoStatus('frog', !isFrog);
    // };


    return (
        <Draggable draggableId={item._id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="card-header mb-2 mt-2 shadow-sm d-flex justify-content-between align-items-center p-1"
                >
                    <div className="d-flex align-items-center"
                        {...provided.dragHandleProps}
                    >
                        <FontAwesomeIcon icon={faBars} className="text-secondary me-2" />
                        <span className="fw-semibold">{item.text}</span>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-sm btn-link p-1 me-1 d-flex align-items-center text-secondary border"
                            onClick={() => deleteToDo(item._id)}
                        >
                            <FontAwesomeIcon icon={faTrash} className="goal-remove-button" />
                        </button>
                        <button
                            className={`btn btn-sm btn-custom p-1 me-1 ${item.focus ? "status-on-track" : "status-not-started"}`}
                            onClick={() => toggleFocus(index, item._id, !item.focus)}
                        >
                            <FontAwesomeIcon icon={faBullseye} />
                        </button>
                        <button
                            className={`btn btn-sm btn-custom p-1 me-1 ${item.frog ? "status-off-track" : "status-not-started"}`}
                            onClick={() => toggleFrog(index, item._id, !item.frog)}
                        >
                            <FontAwesomeIcon icon={faFrog} />
                        </button>
                        <button
                            className={`btn btn-sm btn-custom p-1 ${item.complete ? "status-complete" : "status-not-started"}`}
                            onClick={() => toggleComplete(index, item._id, !item.complete)}
                        >
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </button>
                    </div>
                </div>
            )}
        </Draggable>


    );
};

export default ToDoItem;
