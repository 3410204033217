import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Header = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {
        user ?
          <></> :
          <div className="container-fluid header-bg-serene-blue">
            <div className="p-4 m-0">
              <h1 className="display-4 text-white text-center">Manage Your Life</h1>
            </div>
            <div className="row d-flex justify-content-center p-0 m-0 header-bg-serene-blue">
              <div className="col-7 p-4 m-0 text-center">
                <div className="btn border-white" >
                  <Link className="text-white text-decoration-none" to="/register">Register Today</Link>
                </div>
              </div>
            </div>
          </div>
      }
    </>

  );
};

export default Header;


