// GoalSummaryChart.jsx
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const renderColorfulLegendText = (value, entry) => {
  const { color } = entry;
  return <span style={{ color }}>{value}</span>;
};

const GoalSummaryChart = ({ goalStatusCounts }) => {
  const data = [
    { name: 'Not Started', value: goalStatusCounts.notStarted, fill: '#ced4da' },
    { name: 'Off-Track', value: goalStatusCounts.offTrack, fill: '#f9c74f' },
    { name: 'On-Track', value: goalStatusCounts.onTrack, fill: '#90be6d' },
    { name: 'Complete', value: goalStatusCounts.complete, fill: '#277da1' },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>

      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label
        >
          {
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.fill} />
            ))
          }
        </Pie>
        <Tooltip />
        <Legend formatter={renderColorfulLegendText} />
      </PieChart>
    </ResponsiveContainer>

  );
};

export default GoalSummaryChart;
