// export const apiApp = "http://localhost:3000";
// export const wwwApp = "http://localhost:3001";
// export const golApp = "http://localhost:3002";
// export const finApp = "http://localhost:3003";
// export const admApp = "http://localhost:3004";

export const apiApp = "https://api.movetowardhappy.com";
export const wwwApp = "https://www.movetowardhappy.com";
export const golApp = "https://gol.movetowardhappy.com";
export const finApp = "https://fin.movetowardhappy.com";
export const admApp = "https://adm.movetowardhappy.com";
