import '../../../src/styles/styles.css'; // The path should be relative to the current file

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { api } from "../../api/api.js";
import { useState, memo } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';



const SearchItem = ({ item, linked, user, entity, loading }) => {
    const [userItem, setUserItem] = useState(linked);
    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress'
        });
    };
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    const SearchItemImage = memo(function SearchItemImage({ src }) {
        return <img src={src} decoding="sync" className="img-thumbnail" alt="" />;
    });

    const handleAdd = async (id) => {
        try {
            // API call to add the item
            await api.post(`/api/user/add${entity}`, {
                user: user._id,
                [entity]: id
            });
            setUserItem(false);
            showToast(`${entity.charAt(0).toUpperCase() + entity.slice(1)} added successfully!`);
        } catch (err) {
            console.error(err);
            showToast(`Failed to add ${entity}.`);
        }
    };


    return (
        <div className="container-fluid col-lg-6 col-md-12 col-12-sm p-0 m-0">
            <div className="card shadow mb-3 me-2">
                <div className="card-header  text-secondary fw-bold d-flex justify-content-between">
                    {item.headline}
                    <div className=" d-flex justify-content-end">
                        {!userItem && (
                            <div className="d-flex align-items-center me-1">
                                <FontAwesomeIcon icon={faCheck} className="text-success" style={{ backgroundColor: '#FFFFFF', borderRadius: '50%', padding: '5px' }} />
                            </div>
                        )} {/* Enhanced green check icon */}
                        <span className="badge rounded-pill my-auto text-bg-secondary">{item.category}</span>
                    </div>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-evenly p-2 m-0">
                    <div className="col-lg-6 col-md-12 col-12-sm my-3">
                        <SearchItemImage key={item.img} src={item.img} />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12-sm p-2 card my-3 border d-flex justify-content-between">
                        <p className="text-secondary">{item.description}</p>
                        {user ? (
                            <div
                                className="btn btn-add-goal p-1 w-50"
                                disabled={loading}
                                onClick={() => handleAdd(item._id)}
                            >
                                <p className="fw-bold my-auto">
                                    {userItem ? `Add ${capitalizeFirstLetter(entity)}` : "Add Again"}
                                </p>
                            </div>
                        ) : (
                            <Link to="/login" className="btn btn-add-goal p-1 m-0 w-50">
                                Login to add Add {capitalizeFirstLetter(entity)}
                            </Link>
                        )}
                    </div>
                </div>
            </div >
        </div >
    );
};
export default SearchItem;
