import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import ReactTooltip from 'react-tooltip';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { api } from "../../api/api.js";
import ToDoItem from './ToDoItem'; // Import ToDoItem component

import SummaryToDoChart from './SummaryToDoChart'; // Import SummaryToDoChart component

const ToDoWidget = ({ handleAuthorized }) => {
  const [daysOfWork, setDaysOfWork] = useState([]);
  const [toDos, setToDos] = useState([]);
  // const [toDoChartData, setToDoChartData] = useState([]);
  const [toDoText, setToDoText] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  // Data Secion
  // *************************************

  useEffect(() => {
    buildData();
  }, [user._id]);

  const buildData = async () => {
    setLoading(true);
    try {
      const todos = await api.get(`/api/user/populatetodos/${user._id}`);
      const work = await api.get(`/api/user/populatedaysofwork/${user._id}`);
      console.log("work", work);

      setToDos(todos.data);
      setDaysOfWork(work.data)

    } catch (err) {
      handleAuthorized(false);
    } finally {
      setLoading(false);
    }
  };

  const prepareChartData = () => {
    const chartData = daysOfWork.map(day => {
      const dateFormatted = new Date(day.date).toLocaleDateString();
      const dayStats = {
        date: dateFormatted,  // Use date as key
        week: 0,
        month: 0,
        year: 0,
        inception: 0
      };

      // Calculate stats for each habit
      day.todos.forEach(todo => {
        // Increment the stats based on the existence of a todo item
        dayStats.week += 1;
        dayStats.month += 1;
        dayStats.year += 1;
        dayStats.inception += 1;
      });

      return dayStats;
    });

    return chartData;
  };

  // Todo Section
  // *************************************

  // Function to handle form changes
  const handleChange = (e) => {
    setToDoText(e.target.value);
  };

  // Function to handle adding a new to-do
  const handleAdd = async (e) => {
    e.preventDefault();
    if (!toDoText.trim()) return; // Prevent adding empty to-dos
    try {
      const res = await api.post(`/api/todo/create`, { text: toDoText, user: user._id });
      setToDos([...toDos, res.data]);
      setToDoText("");
    } catch (err) {
      console.error(err);
    }
  };

  // Move the dragged item to the new position Section
  // *************************************


  // Function to handle the drag and drop
  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.index === destination.index) return;

    const updatedTodos = [...toDos];
    const [movedTodoItem] = updatedTodos.splice(source.index, 1);
    updatedTodos.splice(destination.index, 0, movedTodoItem);
    setToDos(updatedTodos);

    // Update the database
    try {
      await api.put("/api/user/updatetodoorder", { todos: updatedTodos });
    } catch (err) {
      console.error(err);
    }
  };

  // Update and changes section
  // *************************************


  //Function called by HabitItem 
  const handleComplete = async (index, todoID, newCompleteState) => {
    setToDos(currentToDos => {
      const newToDos = [...currentToDos];
      newToDos[index].complete = newCompleteState;
      return newToDos;
    });

    const isTodoAlreadyAdded = daysOfWork[0].todos.includes(todoID);
    // Prepare the update operation based on whether the todo is already added
    const updateOperation = isTodoAlreadyAdded
      ? { $pull: { 'todos': todoID } } // Remove the todo if it's already added
      : { $push: { 'todos': todoID } }; // Add the todo if it's not already added

    setDaysOfWork(currentDays => {
      const updatedDays = [...currentDays];
      if (isTodoAlreadyAdded) {
        updatedDays[0].todos = updatedDays[0].todos.filter(id => id !== todoID);
      } else {
        updatedDays[0].todos.push(todoID);
      }
      return updatedDays;
    });

    try {
      updateTodo(todoID, "complete", newCompleteState)
    } catch (error) {
      console.error('Error updating todo complete: ', error);
    }
  };


  // In the parent component
  const handleFocus = async (index, todoID, newFocusState) => {
    setToDos(currentToDos => {
      const newToDos = [...currentToDos];
      newToDos[index].focus = newFocusState;
      return newToDos;
    });
    try {
      updateTodo(todoID, "focus", newFocusState)
    } catch (error) {
      console.error('Error updating todo focus: ', error);
    }
  };

  // In the parent component
  const handleFrog = async (index, todoID, newFrogState) => {
    setToDos(currentToDos => {
      const newToDos = [...currentToDos];
      newToDos[index].frog = newFrogState;
      return newToDos;
    });
    try {
      updateTodo(todoID, "frog", newFrogState)
    } catch (error) {
      console.error('Error updating todo frog: ', error);
    }
  };

  // Function to delete a ToDo
  const deleteToDo = async (id) => {
    try {
      // Update local state
      setToDos(toDos.filter((item) => item._id !== id));

      // API call to delete the ToDo from the database
      await api.delete(`/api/todo/${id}`);
    } catch (err) {
      console.error('Error deleting ToDo:', err);
    }
  };

  const updateTodo = async (toDoID, field, value) => {
    try {
      await api.put(`/api/todo/${toDoID}`, { [field]: value });
      //if field is true update the daysofWork
      try {
        await api.post(`/api/dayofwork/updatetodos`, {
          user: user._id,
          id: toDoID
        });
      } catch (error) {
        console.error('Error updating habit completion: ', error);
      }
    } catch (error) {
      console.error('Error updating todo:', error);
    }
  };


  // console.log("toDoChartData", prepareChartData(daysOfWork));

  return (
    <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-1">
      <div className="card shadow h-100" style={{ borderRadius: '8px', backgroundColor: '#F9F7F7', borderColor: '#DBE2EF' }}>
        <div className="card-header fw-bold text-secondary" style={{ backgroundColor: '#DBE2EF', color: '#112D4E' }}>
          To Dos
        </div>
        <SummaryToDoChart rawData={prepareChartData()} />

        <div className="card-body p-1 m-0">
          <form onSubmit={handleAdd} className="py-1 my-1 border">
            <div className="input-group">
              <button type="submit" className="btn btn-link text-success border p-1 m-1">
                <FontAwesomeIcon icon={faCirclePlus} />
              </button>
              <input
                className="form-control"
                onChange={handleChange}
                required
                type="text"
                placeholder="Add new to-do item..."
                value={toDoText}
                style={{ borderRadius: '5px' }}
              />
            </div>
          </form>
          {loading ? (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : toDos && toDos.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="item-list">
                {provided => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {toDos.map((item, index) => (
                      <ToDoItem
                        key={item._id}
                        index={index}
                        item={item}
                        userID={user._id}
                        deleteToDo={deleteToDo}
                        toggleComplete={handleComplete}
                        toggleFocus={handleFocus}
                        toggleFrog={handleFrog}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <>
              <div className="text-center p-3">
                <p>No Todo's yet! Add some above</p>
              </div>
            </>
          )}

        </div>
        <div className="card-footer" style={{ backgroundColor: '#DBE2EF', color: '#112D4E' }}>
          {toDos.length} To-Dos
        </div>
      </div>
    </div>
  );
};

export default ToDoWidget;
