import '../../../src/styles/styles.css'; // The path should be relative to the current file

import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCircleNotch,
  faCheckCircle,
  faCirclePause,
  faCircleChevronDown,
  faCircleChevronUp,
  faTrash,
  faAnglesUp, // Import the up arrow icon
  faPlus, faMinus
} from "@fortawesome/free-solid-svg-icons";
import { api } from "../../api/api.js"; // Ensure this path is correct

const statuses = [
  { status: 'Not Started', icon: faCircleNotch, colorClass: 'bg-secondary' },
  { status: 'Off-Track', icon: faCircleChevronDown, colorClass: 'bg-warning' },
  { status: 'On-Track', icon: faCircleChevronUp, colorClass: 'bg-info' },
  { status: 'Complete', icon: faCheckCircle, colorClass: 'bg-success' },
];
const getIconForStatus = (status) => {
  switch (status) {
    case 'Not Started':
      return faCirclePause;
    case 'Off-Track':
      return faCircleChevronDown;
    case 'On-Track':
      return faCircleChevronUp;
    case 'Complete':
      return faCheckCircle;
    default:
      return faCirclePause; // Default icon if none of the statuses match
  }
};

const GoalItem = ({ item, index, updateGoalStatus, removeGoal }) => {
  const [goalStatus, setGoalStatus] = useState(item.status);
  const [info, setInfo] = useState({
    specific: item.specific,
    measure: item.measure,
    time: item.time,
  });
  const [isCollapsed, setIsCollapsed] = useState(true);
  const currentStatusClass = statuses.find(s => s.status === goalStatus)?.colorClass || 'bg-light';

  const handleChange = (e) => {
    const { id, value } = e.target; // Destructure the id and value from the event target
    setInfo(info => ({ ...info, [id.split('-')[0]]: value })); // Update the state based on id
  };

  const handleStatusClick = async () => {
    const currentIndex = statuses.findIndex(s => s.status === goalStatus);
    const nextIndex = (currentIndex + 1) % statuses.length;
    const newStatus = statuses[nextIndex].status;
    setGoalStatus(newStatus);
    updateGoalStatus(newStatus, index);
    try {
      await api.put(`/api/user2goal/${item._id}`, { status: newStatus });
    } catch (err) {
      console.error(err);
    }
  };


  // Function to toggle the collapse state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed); // Toggle the state
  };

  const updateDetail = async (field, value) => {
    try {
      // Here you might want to set up a loading state
      const updatedInfo = { ...info, [field]: value };
      const res = await api.put(`/api/user2goal/${item._id}`, updatedInfo);

      // Handle response if needed
      // Then update the state if the API call was successful
      setInfo(updatedInfo);
    } catch (err) {
      console.error(err);
      // Here you should handle the error, maybe show a message to the user
    }
  };

  return (
    <Draggable draggableId={item._id.toString()} index={index}>

      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="card mb-2 mt-2 shadow-sm" // Added a small shadow
        >
          <div className="card-header d-flex justify-content-between align-items-center p-1 ps-2">
            <div className="d-flex align-items-center" {...provided.dragHandleProps}> {/* Ensure flex alignment */}
              <FontAwesomeIcon icon={faBars} className="text-secondary me-2" />
              <span className="fw-semibold">{item.goal.headline}</span> {/* Bold and prevent overflow */}
            </div>
            <div className="justify-content-end" role="group" aria-label="Status buttons">
              <button
                className="btn btn-sm p-1"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseGoal${item._id}`}
                aria-expanded={!isCollapsed}
                aria-controls={`collapseGoal${item._id}`}
                onClick={toggleCollapse}
                title="Show more" // Tooltip text
              >
                {/* Use plus/minus icons for expand/collapse */}
                <FontAwesomeIcon icon={isCollapsed ? faPlus : faMinus} />
              </button>
              <button
                onClick={() => removeGoal(item._id)}
                className="btn btn-sm goal-remove-button p-1 me-1"
                title="Remove Goal" // Tooltip text
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <button
                className={`btn btn-sm btn-custom ${goalStatus === 'Not Started' ? 'status-not-started' : ''}
                            ${goalStatus === 'Off-Track' ? 'status-off-track' : ''}
                            ${goalStatus === 'On-Track' ? 'status-on-track' : ''}
                            ${goalStatus === 'Complete' ? 'status-complete' : ''}`}
                onClick={handleStatusClick}
                aria-label={`Mark as ${goalStatus}`}
              >
                <FontAwesomeIcon icon={getIconForStatus(goalStatus)} className="p-0 m-0" />
              </button>
            </div>
          </div>
          <div
            id={`collapseGoal${item._id}`} // Corrected id to match data-bs-target

            className="collapse" aria-labelledby="headingOne">
            <div className="accordion-body">
              <form onSubmit={(e) => e.preventDefault()} className="px-3 py-2">
                <div className="mb-3">
                  <label htmlFor={`specific-${item._id}`} className="form-label">Detail:</label>
                  <textarea
                    className="form-control"
                    id={`specific-${item._id}`}
                    rows="3"
                    placeholder="Describe the specific goal"
                    value={info.specific}
                    onChange={handleChange}
                    onBlur={updateDetail}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={`measure-${item._id}`} className="form-label">Measurement:</label>
                  <input
                    type="text"
                    className="form-control"
                    id={`measure-${item._id}`}
                    placeholder="Define the success criteria"
                    value={info.measure}
                    onChange={handleChange}
                    onBlur={updateDetail}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={`time-${item._id}`} className="form-label">Deadline:</label>
                  <input
                    type="date"
                    className="form-control"
                    id={`time-${item._id}`}
                    placeholder="Set a deadline"
                    value={info.time}
                    onChange={handleChange}
                    onBlur={updateDetail}
                  />
                </div>
              </form>

            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default GoalItem;
