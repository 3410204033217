import React, { useContext, useEffect, useState } from "react";
import {
    AreaChart,
    Area,
    Legend,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSync,
    faCalendarWeek,
    faCalendarAlt,
    faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/AuthContext";
import { api } from "../../api/api.js";

export const Chart = ({ data, aspect }) => {
    return (
        <div className="chart">
            <ResponsiveContainer width="100%" aspect={aspect}>
                <AreaChart
                    width={730}
                    height={250}
                    data={data}
                    margin={{ top: 10, right: 10, left: 15, bottom: -20 }}
                >
                    <XAxis dataKey="Date" fontSize="12" />
                    <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
                    <Tooltip />
                    <Legend />
                    <Area
                        type="monotone"
                        dataKey="Habits"
                        stackId="1"
                        stroke="#4287f5"
                        fill="#4287f5"
                    />
                    <Area type="monotone" dataKey="Todos" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

const WorkWidget = ({ aspect, title, handleAuthorized }) => {
    const { user } = useContext(AuthContext);
    const [work, setWork] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState("last7Days"); // Initialize with last 7 days filter

    const retrieveWork = async () => {
        try {
            setLoading(true);
            const res = await api.get(`/api/user/populatedaysofwork/${user._id}`);
            setLoading(false);
            return res.data;
        } catch (err) {
            setLoading(false);
            console.log("****************4");
            handleAuthorized(false);
        }
    }

    const applyFilter = (data, duration) => {
        const currentDate = new Date();
        const filteredData = data.filter((item) => {
            const itemDate = new Date(item.date);
            const timeDiff = currentDate - itemDate;
            // console.log("itemDate ", itemDate);

            switch (duration) {
                case "last7Days":
                    return timeDiff <= 7 * 24 * 60 * 60 * 1000;
                case "last28Days":
                    return timeDiff <= 28 * 24 * 60 * 60 * 1000;
                case "last365Days":
                    return timeDiff <= 365 * 24 * 60 * 60 * 1000;
                default:
                    return true;
            }
        });
        return filteredData;
    };

    const refreshGraph = () => {
        try {
            setLoading(false);
            console.log("Starting Refresh");
            setRefresh((prev) => !prev);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        try {
            const getAllWork = async () => {
                const allWork = await retrieveWork();
                if (allWork) {
                    const filteredWork = applyFilter(allWork, filter);
                    const tempArray = filteredWork.map((item) => ({
                        Date: `${new Date(item.date).getMonth() + 1}/${new Date(item.date).getUTCDate()}`,
                        Habits: item.habits.filter(habit => habit.complete).length,
                        Todos: item.todos.length,
                        Goals: item.goals.length,
                    }));

                    // Reverse the order of the data array
                    setWork(tempArray.reverse());
                }
            };
            getAllWork();
        } catch (err) {
            console.error("Error retrieving work data:", err);
        }
    }, [refresh, filter]);

    return (
        <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-1">
            <div className="row card shadow h-100 p-0 m-0">
                <div className="card-header fw-bold text-secondary d-flex justify-space-between">
                    <div className="col-6 p-0 m-0">Work Trend</div>
                    <div className="col-6 p-0 m-0 d-flex justify-content-end">
                        <div className="btn btn-link p-0 m-0" onClick={refreshGraph}>
                            <FontAwesomeIcon icon={faSync} />
                        </div>
                        <button
                            className={`btn btn-link p-0 m-0 ${filter === "last7Days" ? "active" : ""}`}
                            onClick={() => setFilter("last7Days")}
                        >
                            <FontAwesomeIcon icon={faCalendarWeek} />
                        </button>
                        <button
                            className={`btn btn-link p-0 m-0 ${filter === "last28Days" ? "active" : ""}`}
                            onClick={() => setFilter("last28Days")}
                        >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                        </button>
                        <button
                            className={`btn btn-link p-0 m-0 ${filter === "last365Days" ? "active" : ""}`}
                            onClick={() => setFilter("last365Days")}
                        >
                            <FontAwesomeIcon icon={faCalendar} />
                        </button>
                    </div>
                </div>
                <div className="card-body p-1 m-0">
                    {/* Render the Chart component here */}
                    <Chart data={work} aspect={aspect} />
                </div>
                <div className="card-footer ">{work.length} Days</div>
            </div>
        </div>
    );
};

export default WorkWidget;
