import { useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../components/page/Navbar";
import { api } from "../api/api.js";

const Activate = () => {
  const { hash } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const handleActivation = async () => {
    try {
      const res = await api.post(`/api/auth/activate`, { hash });
      if (res.status === 200) {
        setMessage("You have been successfully activated");
        setErrorMessage("");
      }
    } catch (error) {
      if (!error.response) {
        setErrorMessage("No server response");
      } else {
        setErrorMessage("Activation failed");
      }
    }
  };

  const renderMessage = () => {
    if (message) {
      return (
        <>
          <div className="d-flex align-items-center justify-content-center text-success p-2">
            {message}
          </div>
          <div className="text-center text-dark p-2">
            <p>
              Click{" "}
              <a className="text-dark" href="/login">
                here
              </a>{" "}
              to login.
            </p>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="p-4 d-flex justify-content-center">
          Click the button below to activate your account.
        </div>
        <div className="d-flex justify-content-center p-3">
          <div onClick={handleActivation} className="btn btn-add-goal w-25">
            <p className="d-flex justify-content-center my-auto">Activate</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid p-0 m-0 vh-100">
      <Navbar />
      <div className="container-fluid m-0 p-0 h-75">
        <div className="row d-flex justify-content-center align-items-center p-3 m-0 h-100">
          <div className="card rounded-3 shadow-sm col-lg-4 col-md-8 col-sm-10 p-0 m-0">
            <div className="card-header py-2">
              <h4 className="m-0 fw-normal text-center">Activate User</h4>
            </div>
            {errorMessage && (
              <div className="row p-0 m-0">
                <div className="m-0 p-2 text-success text-center">
                  {errorMessage}
                </div>
              </div>
            )}
            {renderMessage()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activate;