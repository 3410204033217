import '../../src/styles/styles.css'; // The path should be relative to the current file

import { useContext, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import GoalWidget from "../components/workspace/GoalWidget";
// import HabitWidget from "../components/workspace/HabitWidget";
import HabitWidgetDaily from "../components/workspace/HabitWidgetDaily";
import ToDoWidget from "../components/workspace/ToDoWidget";
// import BudgetWidget from "../components/workspace/BudgetWidget";
import WorkWidget from "../components/workspace/WorkWidget";

const Workspace = () => {
    const [authorized, setAuthorized] = useState(true);
    const { dispatch } = useContext(AuthContext);
    const { user } = useContext(AuthContext);


    function handleAuthorized(boolValue) {
        setAuthorized(boolValue);
        if (!boolValue) {
            dispatch({ type: "LOGOUT" });
        }
    }

    return (
        <div className="container-fluid p-0">
            <Helmet>
                <title>Productivity Hub: Goals, Habits, and Tasks</title>
                <meta name="description" content="Organize your goals, track your habits, and manage your tasks in one powerful workspace. Stay focused, motivated, and on track with our productivity tools." />
            </Helmet>
            <Navbar />
            {authorized ?
                <div className="d-flex">
                    <div className="container-fluid">
                        <div>
                            <div className="header-content">
                                <h1>Welcome back, {user.username}!</h1>
                                <p>"The secret of getting ahead is getting started. – Mark Twain"</p>

                                {/* Add any interactive elements here */}
                            </div>
                        </div>
                        <div className="row p-1">
                            <GoalWidget handleAuthorized={handleAuthorized} />
                            <HabitWidgetDaily handleAuthorized={handleAuthorized} />
                            <ToDoWidget handleAuthorized={handleAuthorized} />
                        </div>
                        <div className="row p-1">
                            <WorkWidget title="Last 7 Days" aspect={2 / 1} handleAuthorized={handleAuthorized} />
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className="row p-0 m-0">
                        <div className="m-0 p-2 text-success text-center">
                            <p className="m-0 p-0">
                                You've been logged out due to inactivity.<br />
                                Click <Link to="/login">here</Link> to login.
                            </p>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Workspace;