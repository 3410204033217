import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import GoalItem from './GoalItem';
import GoalSummaryChart from './GoalSummaryChart';
import { AuthContext } from "../../context/AuthContext";
import { api } from "../../api/api.js";

const GoalWidget = ({ handleAuthorized }) => {
  const { user } = useContext(AuthContext);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const retrieveGoals = async () => {
      try {
        const response = await api.get(`/api/user/populategoals/${user._id}`);
        setGoals(response.data);
      } catch (error) {
        handleAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    retrieveGoals();
  }, [user._id, handleAuthorized]);

  const updateGoalStatus = async (status, index) => {
    console.log("in updateGoalStatus");
    console.log("status: " + status);
    console.log("index: " + index);

    const updatedGoals = [...goals];
    updatedGoals[index].status = status;

    try {
      await api.put(`/api/user2goal/${updatedGoals[index]._id}`, { status });
      setGoals(updatedGoals);
    } catch (error) {
      console.error(error);
    }
  };

  const suggestMilestones = async (goalDescription) => {
    try {
      const data = {
        prompt: `Create a list of milestones for the following goal: ${goalDescription}`,
        // Other necessary parameters for OpenAI API
      };

      const response = await fetch("https://api.openai.com/v1/engines/davinci/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer YOUR_OPENAI_API_KEY`
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      console.log(result.choices[0].text);
      return result.choices[0].text; // Parse and return the suggestion
    } catch (error) {
      console.error("Error suggesting milestones: ", error);
      return null;
    }
  };


  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination || source.index === destination.index) return;

    const reorderedGoals = Array.from(goals);
    const [reorderedItem] = reorderedGoals.splice(source.index, 1);
    reorderedGoals.splice(destination.index, 0, reorderedItem);

    setGoals(reorderedGoals);
    await api.put("/api/user/updategoalorder", { goals: reorderedGoals });
  };

  const goalStatusCounts = calculateGoalStatusCounts();

  function calculateGoalStatusCounts() {
    const total = goals.length;
    const statusCounts = goals.reduce((acc, { status }) => {
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, {});

    return {
      notStarted: statusCounts['Not Started'] || 0,
      offTrack: statusCounts['Off-Track'] || 0,
      onTrack: statusCounts['On-Track'] || 0,
      complete: statusCounts['Complete'] || 0,
      notStartedPercent: ((statusCounts['Not Started'] || 0) / total) * 100,
      offTrackPercent: ((statusCounts['Off-Track'] || 0) / total) * 100,
      onTrackPercent: ((statusCounts['On-Track'] || 0) / total) * 100,
      completePercent: ((statusCounts['Complete'] || 0) / total) * 100,
    };
  }

  const removeGoal = async (goalId) => {
    try {
      await api.delete(`/api/user2goal/${goalId}`);
      setGoals(goals.filter(goal => goal._id !== goalId));
    } catch (error) {
      console.error(error);
    }
  };

  console.log("goalStatusCounts: " + JSON.stringify(goalStatusCounts));
  return (
    <div className="col-lg-4 col-md-12 col-sm-12 m-0 p-1">
      <div className="card shadow h-100">
        <div className="card-header fw-bold text-secondary">Goals</div>
        <div className="card-body p-1 m-0">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : goals.length === 0 ? (
            <div className="text-center p-3">
              <p>No goals yet! Ready to set some?</p>
              <a href="/goals" className="btn btn-add-goal">Go to Goals Page</a>
            </div>
          ) : (
            <>
              <GoalSummaryChart goalStatusCounts={goalStatusCounts} />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="goals">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {goals.map((goal, index) => (
                        <GoalItem
                          key={goal._id}
                          item={goal}
                          index={index}
                          updateGoalStatus={updateGoalStatus}
                          removeGoal={removeGoal}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </div>
        <div className="card-footer text-muted">{goals.length} Goals</div>
      </div>
    </div>
  );
};


export default GoalWidget;
