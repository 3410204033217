const MailList = () => {
  return (
    <div className="container-fluid bg-light p-3 text-center">
      <p className=" my-4">Register Today!</p>
      {/* <p className=" mb-4">Sign up and we'll send updates to you</p>
      <div className="input-group justify-content-center">
        <input type="input-group-text" placeholder="Your Email" />
        <button className="btn btn-success" >Subscribe</button>
      </div> */}
    </div>
  )
}

export default MailList