import { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";

const AccountSubscription = ({ subscription }) => {
    return (
        <section>
            <p>
                Status: {subscription.status}
            </p>

            <p>
                Card last4: {subscription.default_payment_method?.card?.last4}
            </p>

            <p>
                Current period end: {(new Date(subscription.current_period_end * 1000).toString())}
            </p>

            {/* <Link to={{ pathname: '/cancel', state: { subscription: subscription.id } }}>Cancel</Link> */}
        </section>
    )
}

const Profile = ({ inputs, title }) => {
    const { user } = useContext(AuthContext);
    const [info, setInfo] = useState({});
    const { loading, error, message, dispatch } = useContext(AuthContext);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const subscriptions = await api.get("/api/stripe/getsubscriptions")
            // console.log(subscriptions)
            setSubscriptions(subscriptions.data);
        }
        fetchData();
    }, []);

    if (!subscriptions) {
        return '';
    }

    const handleChange = (e) => {
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const res = await api.put(`/api/auth/${user._id}`,
                {
                    ...info,
                }
            );
            setInfo(res.data.entity);
        } catch (err) {
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        try {
            const res = await api.delete(`/api/user/${user._id}`);
            dispatch({ type: "LOGOUT", payload: res.data });
        } catch (err) {
        }
    };

    return (
        <div className="container-fluid p-0 m-0">
            <Navbar />
            <div className="container-fluid m-0 p-0 h-100 d-flex justify-content-center align-items-center">
                <div className="row col-lg-4 col-md-8 col-sm-10 p-1 m-0 ">
                    <div className="card rounded-3 p-0 m-0">
                        <div className="card-header py-1">
                            <h4 className="m-0 fw-normal text-center">Update Profile</h4>
                        </div>
                        {error ?
                            <>
                                <div className="row p-0 m-0">
                                    <div className="m-2 p-2 text-danger d-flex align-items-center justify-content-center">
                                        {error.message}
                                    </div>
                                </div>
                            </> :
                            <></>
                        }
                        {message ?
                            <>
                                <div className="row p-0 m-0">
                                    <div className="m-2 p-2 text-success d-flex align-items-center justify-content-center">
                                        {message}
                                    </div>
                                </div>
                            </>
                            :
                            <></>
                        }

                        {user ?
                            <form className="card-body px-4 m-0">
                                <div className="p-0 my-3 form-group">
                                    <label htmlFor="username">Email</label>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        placeholder={user.email}
                                        id="email"
                                        value={info.email}
                                        className="form-control"
                                        disabled={true}
                                    />
                                </div>
                                <div className="p-0 my-3 form-group">
                                    <label htmlFor="username">New User Name</label>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        placeholder={user.username}
                                        id="username"
                                        value={info.username}
                                        className="form-control"
                                    />
                                </div>
                                <div className="p-0 my-3 form-group">
                                    <label htmlFor="password">New Password</label>
                                    <input
                                        onChange={handleChange}
                                        type="password"
                                        placeholder={"********"}
                                        id="password"
                                        value={info.password}
                                        className="form-control"
                                    />
                                </div>
                                <div className="text-center">
                                    <div disabled={loading} onClick={handleUpdate} className="btn btn-add-goal w-25">
                                        <p className="d-flex justify-content-center my-auto">Update</p>
                                    </div>
                                </div>
                            </form>
                            :
                            <></>
                        }
                    </div>
                    {user ?
                        <div className="card rounded-3 p-0 mt-5">
                            <div className="card-header">
                                <h4 className="m-0 fw-normal text-center">Delete Profile</h4>
                            </div>
                            <div className="text-center p-4">
                                <div disabled={loading} onClick={handleDelete} className="btn btn-add-goal w-25">
                                    <p className="d-flex justify-content-center my-auto">Delete</p>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="card rounded-3 p-0 mt-5">
                        <div className="card-header">
                            <h4 className="m-0 fw-normal text-center">Subscriptions</h4>
                        </div>
                        <div className="card-body">
                            <div id="subscriptions">
                                {subscriptions.map(s => {
                                    return <AccountSubscription key={s.id} subscription={s} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Profile;
