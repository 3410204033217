import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const HabitBarChart = ({ habitData }) => {
    // Assuming habitData has 'week', 'month', 'year', 'inception' properties

    const data = [
        { name: '7 Days', Percent: (habitData.week / 7) * 100 },
        { name: '28 Days', Percent: (habitData.month / 28) * 100 },
        { name: '365 Days', Percent: (habitData.year / 365) * 100 },
        // Assuming 'inception' represents total days since the habit was added
        // { name: 'Since Inception', percentage: (habitData.inception / habitData.inception) * 100 }
    ];

    return (
        <ResponsiveContainer width="90%" height={160}>
            <BarChart layout="vertical" data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 100]} />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Legend />
                <Bar dataKey="Percent" fill="#f9c74f" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default HabitBarChart;
