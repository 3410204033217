import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faCalendarAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";

const SummaryToDoChart = ({ rawData }) => {
    const [timeFrame, setTimeFrame] = useState("week");
    const [filteredData, setFilteredData] = useState([]);

    // Function to filter data based on the selected timeframe
    const filterData = (timeFrame) => {
        const currentDate = new Date();
        const filtered = rawData
            .filter(item => {
                const itemDate = new Date(item.date);
                const timeDiff = currentDate - itemDate;

                switch (timeFrame) {
                    case "week":
                        return timeDiff <= 7 * 24 * 60 * 60 * 1000;
                    case "month":
                        return timeDiff <= 30 * 24 * 60 * 60 * 1000;
                    case "year":
                        return timeDiff <= 365 * 24 * 60 * 60 * 1000;
                    default:
                        return true;
                }
            })
            .map(item => {
                return {
                    dateFormatted: `${new Date(item.date).getMonth() + 1}/${new Date(item.date).getDate()}`,
                    Complete: item[timeFrame]
                };
            });

        // Sort data by date in ascending order
        return filtered.sort((a, b) => new Date(a.dateFormatted) - new Date(b.dateFormatted));
    };

    // Update the chart data when the time frame changes
    useEffect(() => {
        setFilteredData(filterData(timeFrame));
        // console.log("timeFrame", filterData(timeFrame));

    }, [timeFrame, rawData]);

    // console.log("rawdata", rawData);

    return (
        <div className="chart-container m-0 p-0">
            <div className="button-group d-flex justify-content-end m-0">
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "week" ? "active" : ""}`}
                    onClick={() => setTimeFrame("week")}
                >
                    <FontAwesomeIcon icon={faCalendarWeek} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "month" ? "active" : ""}`}
                    onClick={() => setTimeFrame("month")}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "year" ? "active" : ""}`}
                    onClick={() => setTimeFrame("year")}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={filteredData} margin={{ top: 5, right: 0, left: -20, bottom: 0 }} // Adjust left margin here
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dateFormatted" tick={{ fontSize: 12 }} />
                    <YAxis
                        tick={{ fontSize: 12 }}
                        domain={[0, 'dataMax']}
                        allowDecimals={false} // This ensures only whole numbers are displayed
                    />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="Complete" stroke="#277da1" fill="#f9c74f" />
                </AreaChart>
            </ResponsiveContainer>

        </div>
    );
};

export default SummaryToDoChart;
