import '../../src/styles/styles.css'; // The path should be relative to the current file

import { useState, useContext, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import { AuthContext } from "../context/AuthContext";
import { api } from "../api/api.js";
import SearchItem from "../components/page/SearchItem.js"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const List = ({ entity }) => {
  const { user } = useContext(AuthContext);
  const [category, setCategory] = useState("All");
  const [allThings, setAllThings] = useState([]);
  const [userThings, setUserThings] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeCategory = async (e) => {
    console.log("in changeCategory");
    console.log(e.target.id);
    setCategory(e.target.id);
  }

  const checkIfLinked = (id) => {
    return !(userThings.indexOf(id) > -1)
  }

  const handleAdd = async (id) => {
    console.log("in handleAdd");

    try {

      // API call to add the item
      await api.post(`/api/user/add${entity}`, {
        user: user._id,
        [entity]: id
      });
    } catch (err) {
      console.error(err);
    }
  };

  // const handleRemove = async (id) => {
  //   try {
  //     setUserItem(prevState => !prevState);
  //     const res = await api.post(`/api/user/remove${entity}`,
  //       {
  //         user: user._id,
  //         [entity]: id
  //       }
  //     );
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const retrieveAllThings = async () => {
    try {
      const res = await api.get(`/api/${entity}`);
      return res.data;
    } catch (err) {
    }
  }

  const retrieveCategoryThings = async () => {
    console.log("in retrieveCategoryThings");

    try {
      setLoading(true);
      let res = "";
      if (category === "All") {
        res = await api.get(`/api/${entity}`);
      }
      else {
        res = await api.get(`/api/${entity}/category/${category}`);
      }
      setLoading(false);
      return res.data;
    } catch (err) {
      setLoading(false);
    }
  }

  const retrieveUserThings = async () => {
    console.log("in retrieveCategoryThings");
    try {
      const res = await api.get(`/api/user/getuser${entity}s/${user._id}`);
      const userTempThings = res.data.map((item) => {
        if (entity === "goal") { return item.goal }
        if (entity === "habit") { return item.habit }
        if (entity === "locale") { return item.locale }
      });
      return userTempThings
    } catch (err) {
    }
  }

  useEffect(() => {
    console.log("in base useEffect");
    const getAllThings = async () => {
      const allThings = await retrieveAllThings();
      if (allThings) {
        setAllThings(allThings);
      }
    };
    const getUserThings = async () => {
      const allUserThings = await retrieveUserThings();
      if (allUserThings) {
        setUserThings(allUserThings);
      }
    };
    getAllThings();
    if (user) {
      getUserThings();
    }
  }, []);

  useEffect(() => {
    console.log("in entity change useEffect");

    setCategory("All");
    const getAllThings = async () => {
      const allThings = await retrieveAllThings();
      if (allThings) {
        setAllThings(allThings);
      }
    };
    const getUserThings = async () => {
      const allUserThings = await retrieveUserThings();
      if (allUserThings) {
        setUserThings(allUserThings);
      }
    };
    getAllThings();
    if (user) {
      getUserThings();
    }
  }, [entity]);

  useEffect(() => {
    console.log("in category change useEffect");

    const getCategoryThings = async () => {
      const categoryThings = await retrieveCategoryThings();
      if (categoryThings) {
        setAllThings(categoryThings);
      }
    };
    getCategoryThings();
  }, [category]);

  return (
    <div>
      <Helmet>
        {entity === "goal" ?
          <title>Choose Goals to improve your life</title>
          :
          <title>Choose Habits to achieve your goals</title>
        }
        <meta name="description" content="Select from a preconfigured list of Goals and Habits" />
      </Helmet>
      <Navbar />
      <Header />
      <div className="row d-flex justify-content-center m-0 p-2">
        <div className="col-lg-2 col-md-4 col-sm-4 p-1 m-0">
          <div className="card shadow p-3" >
            <div className="card-body  text-secondary p-0 mb-1">Filter</div>
            <hr className="p-0 m-1" />
            <label htmlFor="All" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="All"
                onChange={(e) => changeCategory(e)}
                checked={category === "All"}
              />
              All {entity}s
            </label>
            <hr className="p-0 m-1" />
            <label htmlFor="Career" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Career"
                onChange={(e) => changeCategory(e)}
                checked={category === "Career"}
              />
              Career
            </label>
            <label htmlFor="Development" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Development"
                onChange={(e) => changeCategory(e)}
                checked={category === "Development"}
              />
              Development
            </label>
            <label htmlFor="Family" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Family"
                onChange={(e) => changeCategory(e)}
                checked={category === "Family"}
              />
              Family
            </label>
            <label htmlFor="Finance" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Finance"
                onChange={(e) => changeCategory(e)}
                checked={category === "Finance"}
              />
              Finance
            </label>
            <label htmlFor="Fun" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Fun"
                onChange={(e) => changeCategory(e)}
                checked={category === "Fun"}
              />
              Fun
            </label>
            <label htmlFor="Health" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Health"
                onChange={(e) => changeCategory(e)}
                checked={category === "Health"}
              />
              Health
            </label>
            <label htmlFor="Relationship" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Relationship"
                onChange={(e) => changeCategory(e)}
                checked={category === "Relationship"}
              />
              Relationship
            </label>
            <label htmlFor="Spiritual" className="form-check-label m-0  mb-1 d-flex flex-nowrap">
              <input
                type="radio"
                className="form-check-input me-2"
                id="Spiritual"
                onChange={(e) => changeCategory(e)}
                checked={category === "Spiritual"}
              />
              Spiritual
            </label>
          </div >
        </div>
        <div className="col-lg-9 col-md-8 col-sm-8 p-1 m-0 d-flex flex-wrap">
          {loading ? (
            <div className="row w-100 d-flex justify-content-center  align-items-center p-3 m-0">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            allThings &&
            allThings.map((item) => (
              <SearchItem
                key={item._id}
                item={item}
                linked={checkIfLinked(item._id)}
                user={user}
                entity={entity}
                loading={loading}
                handleAdd={() => handleAdd(item._id)} // Pass handleAdd as a prop
              />
            ))
          )}
        </div>
      </div >
    </div >
  );
};

export default List;
