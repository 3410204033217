import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import { api } from "../api/api.js";


const Register = ({ inputs, title }) => {
    const [info, setInfo] = useState(
        { goalsLevel: "free" }
    );
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const handleChange = (e) => {
        console.log(e.target.id + " " + e.target.value)
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const res = await api.post("/api/auth/register", { ...info });
            if (res.status === 200) {
                setMessage("Registration success - Check your email for activation link.");
                setErrorMessage(undefined);
            } else {
                setErrorMessage(res.data.message || "An unknown error occurred");
                setMessage(undefined);
            }
        } catch (err) {
            if (!err.response) {
                // Network or other error, not a response from the server
                setErrorMessage("Registration Unavailable");
            } else {
                // The server responded with a status other than 2xx
                // You can access the server response message here
                setErrorMessage(err.response.data.message || "Could not register");
            }
            setMessage(undefined);
        }
    };


    return (
        <div className="container-fluid p-0 m-0 vh-100">
            <Helmet>
                <title>Register for Empowering Goals & Habits Management</title>
                <meta name="description" content="Join Goals & Habits to register and embark on a journey towards a more fulfilling life. Choose your membership level, set empowering goals, and cultivate productive habits. Get started now!" />
            </Helmet>
            <Navbar />
            <div className="container-fluid m-0 p-0 h-100">
                <div className="row d-flex justify-content-center align-items-center p-3 m-0 h-100">
                    <div className="col-lg-4 col-md-8 col-sm-10 p-0">
                        <div className="card rounded-3 ">
                            <div className="card-header py-1">
                                <h4 className="m-0 fw-normal text-center">Register</h4>
                            </div>
                            {errorMessage &&
                                <>
                                    <div className="row p-0 m-0">
                                        <div className="m-0 p-2 text-success text-center">
                                            {errorMessage}
                                        </div>
                                    </div>
                                </>
                            }
                            {message &&
                                <>
                                    <div className="row p-0 m-0">
                                        <div className="m-0 p-2 text-success text-center">                                            {message}
                                        </div>
                                    </div>
                                </>
                            }

                            <form className="card-body px-4 m-0">
                                <div className="p-0 my-3 form-group">
                                    <label htmlFor="username">User Name</label>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="User name"
                                        id="username"
                                        value={info.username}
                                        className="form-control"
                                    />
                                </div>
                                <div className="p-0 my-2 form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        onChange={handleChange}
                                        type="text"
                                        placeholder="Email"
                                        id="email"
                                        value={info.email}
                                        className="form-control"
                                    />
                                </div>
                                <div className="p-0 my-2 form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        onChange={handleChange}
                                        type="password"
                                        placeholder="Password"
                                        id="password"
                                        value={info.password}
                                        className="form-control"
                                    />
                                </div>
                                <div className="p-0 my-2 form-group">
                                    <label htmlFor="goalsLevel">Goals & Habits Membership Level</label>
                                    <select
                                        id="goalsLevel"
                                        className="form-select"
                                        onChange={handleChange}
                                        defaultValue="free"
                                    >
                                        <option value="free" >Free</option>
                                        <option value="unlimited" >Unlimited</option>
                                    </select>
                                </div>
                                <div className="d-flex p-0 mt-2 justify-content-center">
                                    <div onClick={handleClick} className="btn btn-add-goal w-25">
                                        <p className="d-flex justify-content-center my-auto">Register</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row row-cols-1 row-cols-md-2 p-0 m-0 mt-4 text-center">
                            <div className="col p-1 m-0">
                                <div className="card rounded-3 shadow-sm">
                                    <div className="card-header py-1">
                                        <h4 className="m-0 fw-normal">Free</h4>
                                    </div>
                                    <div className="card-body">
                                        <h1 className="card-title pricing-card-title">$0<small className="text-muted fw-light">/mo</small></h1>
                                        <ul className="list-unstyled m-1">
                                            <li>Track 5 goals</li>
                                            <li>Track 5 habits</li>
                                            <li>Track 10 todos</li>
                                            <li>Monitor one week trends</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col p-1 m-0">
                                <div className="card rounded-3 shadow-sm">
                                    <div className="card-header py-1">
                                        <h4 className="m-0 fw-normal">Unlimited</h4>
                                    </div>
                                    <div className="card-body">
                                        <h1 className="card-title pricing-card-title">$2.99<small className="text-muted fw-light">/mo</small></h1>
                                        <ul className="list-unstyled m-1">
                                            <li>Track unlimited goals</li>
                                            <li>Track unlimited habits</li>
                                            <li>Track unlimited todos</li>
                                            <li>Monitor all trends</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;