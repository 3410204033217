import { wwwApp } from "../../urls"
import { useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { api } from "../../api/api.js";

const Navbar = () => {
  const { user } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGOUT" });
    try {
      // Await the completion of the POST request
      //await api.post(`/api/auth/logout`);
      // Navigate to the home page only after the POST request is completed
      navigate("/home");
    } catch (err) {
      console.error(err);
      // Handle any errors that occur during the POST request
    }
  };



  return (
    <nav className="navbar navbar-expand-lg navbar-custom sticky-top">
      <div className="container-fluid">
        <a className="navbar-brand text-white" href={wwwApp} >Move Toward Happy</a>
        <button className="navbar-toggler navbar-toggler-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
          aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav ms-auto my-2 my-lg-0">
            <li className="nav-item"><NavLink className="nav-link" to="/">Home</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/goals">Goals</NavLink></li>
            <li className="nav-item"><NavLink className="nav-link" to="/habits">Habits</NavLink></li>
            {user ?
              <>
                {(user.goalsLevel === "free") ?
                  <li className="nav-item"><NavLink className="nav-link" to="/plans">Upgrade</NavLink></li>
                  :
                  <li className="nav-item"><NavLink className="nav-link" to="/plans">Downgrade</NavLink></li>
                }
                <li className="nav-item"><NavLink className="nav-link" to="/workspace">Manage</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/profile">Profile</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link" to="/logout" onClick={handleClick}>Logout</NavLink></li>
              </>
              :
              <li className="nav-item"><NavLink className="nav-link" to="/login" >Login</NavLink></li>
            }

            <li className="nav-item"><NavLink className="nav-link" to="/instructions">Help</NavLink></li>
          </ul>
        </div>
      </div>
    </nav >
  );
};

export default Navbar;