import '../../../src/styles/styles.css'; // The path should be relative to the current file

import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCheckCircle, faBullseye, faFrog, faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from 'react-tooltip';
import HabitPieChart from './HabitBarChart.js'; // Adjust the path as needed

const HabitItem = ({
    habitStartDate,
    order,
    dayOfWork,
    habitData,
    habitStats,
    //
    removeHabit,
    toggleComplete,
    toggleFocus,
    toggleFrog,
    submitStartTime,
    submitDetails,
    submitEndTime

}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [detailText, setDetailText] = useState(habitData.user2habit?.detail || "");
    const [startTime, setStartTime] = useState(habitData.user2habit?.start || "");
    const [endTime, setEndTime] = useState(habitData.user2habit?.end || "");

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    // Computed class for the collapse element
    const collapseClass = isCollapsed ? 'collapse' : 'collapse show';

    // Optional: Styles for the collapse element (if you want to use inline styles)
    const collapseStyle = { transition: 'height 0.35s ease' };

    // Local handlers for form inputs
    const handleDetailChange = (e) => {
        setDetailText(e.target.value);
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    // OnBlur handlers to submit data
    const onBlurDetail = () => {
        submitDetails(habitData.user2habit._id, detailText);
    };

    const onBlurStartTime = () => {
        submitStartTime(habitData.user2habit._id, startTime);
    };

    const onBlurEndTime = () => {
        submitEndTime(habitData.user2habit._id, endTime);
    };

    return (
        <>
            <Draggable draggableId={habitData._id.toString()} index={order}>
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="card mb-2 mt-2 shadow-sm"
                    >
                        <div className="card-header d-flex justify-content-between align-items-center p-1 ps-2">
                            <div className="d-flex align-items-center" {...provided.dragHandleProps}>
                                <FontAwesomeIcon icon={faBars} className="text-secondary me-2" />
                                <span className="fw-semibold">{habitData.habit.text}</span>
                            </div>
                            <div className="habit-buttons p-0 m-0" role="group" aria-label="Status buttons">
                                <button
                                    className="btn btn-sm btn-custom p-1"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#collapseHabit${habitData._id}`}
                                    aria-expanded={!isCollapsed}
                                    aria-controls={`collapseHabit${habitData._id}`}
                                    onClick={toggleCollapse}
                                    title="Show more"
                                >
                                    <FontAwesomeIcon icon={isCollapsed ? faPlus : faMinus} />
                                </button>
                                <button
                                    onClick={() => removeHabit(habitData.habit._id)}
                                    className="btn btn-sm goal-remove-button p-1 me-1"
                                    title="Remove Habit" // Tooltip text
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <button
                                    className={`btn btn-sm btn-custom p-1 me-1 ${habitData.focus ? "status-on-track" : "status-not-started"}`}
                                    onClick={() => toggleFocus(order, dayOfWork, habitData.habit._id, !habitData.focus)} // Pass item ID to the complete handler
                                    data-tip data-for="focusTip"
                                >
                                    <FontAwesomeIcon icon={faBullseye} />
                                </button>
                                <ReactTooltip id="focusTip" place="top" effect="solid">
                                    Focus
                                </ReactTooltip>
                                <button
                                    className={`btn btn-sm btn-custom p-1 me-1 ${habitData.frog ? "status-off-track" : "status-not-started"}`}
                                    onClick={() => toggleFrog(order, dayOfWork, habitData.habit._id, !habitData.frog)} // Pass item ID to the complete handler
                                    data-tip data-for="frogTip"
                                >
                                    <FontAwesomeIcon icon={faFrog} className="pe-none" />
                                </button>
                                <ReactTooltip id="frogTip" place="top" effect="solid">
                                    Frog (difficult task)
                                </ReactTooltip>
                                <button
                                    className={`btn btn-sm btn-custom p-1  ${habitData.complete ? "status-complete" : "status-not-started"}`}
                                    onClick={() => toggleComplete(order, dayOfWork, habitData.habit._id, !habitData.complete)} // Pass item ID to the complete handler
                                    data-tip data-for="completeTip"
                                >
                                    <FontAwesomeIcon icon={faCheckCircle} className="pe-none" />
                                </button>
                                <ReactTooltip id="completeTip" place="top" effect="solid">
                                    Complete
                                </ReactTooltip>
                            </div>
                        </div>
                        <div id={`collapseHabit${habitData._id}`} className={collapseClass} style={collapseStyle}>
                            <div className="card-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor={`details-${habitData._id}`} className="form-label">Detail:</label>
                                        <textarea
                                            className="form-control"
                                            id={`details-${habitData._id}`}
                                            value={detailText}
                                            onChange={(e) => handleDetailChange(e)} // Update to handle changes for specific user2habit
                                            onBlur={() => onBlurDetail()} // Pass user2habit ID to the submit handler
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor={`startTime-${habitData._id}`} className="form-label">Start Time:</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            id={`startTime-${habitData._id}`}
                                            value={startTime}
                                            onChange={(e) => handleStartTimeChange(e)} // Update to handle changes for specific user2habit
                                            onBlur={() => onBlurStartTime()} // Pass user2habit ID to the submit handler
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor={`endTime-${habitData._id}`} className="form-label">End Time:</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            id={`endTime-${habitData._id}`}
                                            value={endTime}
                                            onChange={(e) => handleEndTimeChange(e)} // Update to handle changes for specific user2habit
                                            onBlur={() => onBlurEndTime()} // Pass habitData ID to the submit handler
                                        />
                                    </div>
                                </form>
                            </div>
                            <HabitPieChart habitData={habitStats} />
                            <div className="accordion-body">
                                <div className="summary-section">
                                    <div className="summary-title">Completion Metrics:</div>
                                    <ul className="m-0 p-0 ps-3">
                                        <li><b>{habitStats?.week}</b> of last <b>7</b> days</li>
                                        <li><b>{habitStats?.month}</b> of last <b>28</b> days</li>
                                        <li><b>{habitStats?.year}</b> of last <b>365</b> days</li>
                                        <li><b>{habitStats?.inception}</b> since habit was added</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>



        </>
    );
};

export default HabitItem;