import { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import Header from "../components/page/Header";
import Navbar from "../components/page/Navbar";
import { api } from "../api/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../context/AuthContext";
const stripePromise = loadStripe('pk_test_51NfMm2Cwvcp7G6axIcvTtQlKUVL7yw8djLMSu5S9pHCLHS9V3I3vJvYztmXKrLgckrWGcY43yBLB6f7cyX8Jp0vZ00uOAfx5KV');

const Plans = () => {
    const { user, dispatch } = useContext(AuthContext);
    const [subscription, setSubscription] = useState(user.goalsLevel);
    const [stripeCustomerId, setStripeCustomerId] = useState(user.stripeCustomerId);

    useEffect(() => {
        setSubscription(user.goalsLevel);
    }, [user.goalsLevel]);

    const cancelUnlimited = async (subscriptionId) => {
        try {
            setSubscription("free");

            const res = await api.post(`/api/stripe/cancelunlimited`, {
                userId: user._id,
                subscription: user.subscription,
            });

            dispatch({ type: "LOGIN_PASS", payload: res.data });
        } catch (error) {
            console.error('Error canceling subscription:', error);
        }
    };

    const makeUnlimited = async (priceId) => {
        try {
            const res = await api.post(`/api/stripe/createcheckout`, {
                stripeCustomerId: stripeCustomerId,
                priceId: priceId
            });

            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({ sessionId: res.data.id });

            if (error) {
                console.error('Error:', error);
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <div>
            <div className="container-fluid p-0 m-0">
                <Helmet>
                    <title>Empowering Goals & Habits Management - Choose Your Membership Level</title>
                    <meta name="description" content="Join Goals & Habits to register and embark on a journey towards a more fulfilling life. Select your membership level, set empowering goals, and cultivate productive habits. Get started now!" />
                </Helmet>
                <Navbar />
                <Header />
                <div className="container py-5">
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                            <div className="col">
                                <div className="card mb-4 rounded-3 shadow-sm h-100">
                                    <div className="card-header py-3">
                                        <h4 className="my-0 fw-normal">Free</h4>
                                    </div>
                                    <div className="card-body">
                                        <h1 className="card-title pricing-card-title">$0<small className="text-muted fw-light">/mo</small></h1>
                                        <ul className="list-unstyled mt-3 mb-4">
                                            <li>Track 5 goals</li>
                                            <li>Track 5 habits</li>
                                            <li>Track 10 todos</li>
                                            <li>Monitor one week trends</li>
                                        </ul>
                                        {user && subscription === "free" && (
                                            <button
                                                type="button"
                                                className="w-25 btn p-1 m-0 btn-secondary"
                                            >
                                                Current Plan
                                            </button>
                                        )}
                                        {user && subscription !== "free" && (
                                            <button
                                                type="button"
                                                id="free"
                                                onClick={cancelUnlimited}
                                                className="w-25 btn p-1 m-0 btn-add-goal"
                                            >
                                                Switch to Free
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card mb-4 rounded-3 shadow-sm h-100">
                                    <div className="card-header py-3">
                                        <h4 className="my-0 fw-normal">Unlimited</h4>
                                    </div>
                                    <div className="card-body">
                                        <h1 className="card-title pricing-card-title">$2.99<small className="text-muted fw-light">/mo</small></h1>
                                        <ul className="list-unstyled mt-3 mb-4">
                                            <li>Track unlimited goals</li>
                                            <li>Track unlimited habits</li>
                                            <li>Track unlimited todos</li>
                                            <li>Monitor all trends</li>
                                        </ul>
                                        {user && subscription === "unlimited" && (
                                            <div className="text-primary">
                                                <button
                                                    type="button"
                                                    className="w-25 btn p-1 m-0 btn-secondary"
                                                >
                                                    Current Plan
                                                </button>
                                            </div>
                                        )}
                                        {user && subscription !== "unlimited" && (
                                            <button
                                                type="button"
                                                id="unlimited"
                                                onClick={() => makeUnlimited('price_1NkSCuCwvcp7G6axFrdJCAMK')}
                                                className="w-25 btn p-1 m-0 btn-add-goal"
                                            >
                                                Switch to Unlimited!
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Plans;
